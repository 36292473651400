@import 'styles/_variables';

@import url('https://fonts.googleapis.com/css?family=Sarabun:400,700|Baloo+Thambi+2:400&display=swap');

body {
  margin: 0;
  font-family: Sarabun, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $background-gray;
  min-width: 21rem;
}
