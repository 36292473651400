@import '../../styles/_mixins';
@import '../../styles/_variables';

.Nav {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.Title {
  font-family: 'Baloo Thambi 2', cursive, sans-serif;
  margin-left:2rem;
  width: 100%;
  color: $white;
  font-weight: 700;
  font-size: 1.5rem;
  font-variant: small-caps;
}

@include breakpoint('small') {
  .Nav {
    justify-content: flex-start;
  }

}
