@import '../../../styles/_variables';

.Content {
  position: relative;
  background: $gray;
  border-radius: 2px;
  padding: 1em;
  border-radius: 10px;

  &::before {
    content: '';
    position: absolute;
    top: 13px;
    right: 100%;
    border: 10px solid transparent;
    border-right: 10px solid $gray;
  }

  .Info {
    background: lighten($gray, 10);
    box-shadow:  inset 0 2px 0 rgba(lighten($gray, 5), 0.5);
    border-radius: 2px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    span {
      display: inline-block;
      margin: 0.5rem 1rem;
    }

    i {
      margin-right: 0.5rem;
    }

  }

  p {
    text-align: justify;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .Skills {
    font-size: 0.8rem;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      background: lighten($gray, 20);
      border-radius: 2px;
      display: inline-block;
      padding: 2px 10px;
      color: rgba(255,255,255,0.7);
      margin: 3px 2px;
      text-align: center;
      flex-grow: 1;
    }
  }
}
