@import '../../styles/_variables';
@import '../../styles/_mixins';

.Home {
    @include main-layout();
}

.Photo {
  margin-top: 3rem;
  img {
    border-radius: 50%;
    height: 200px;
    width: 200px;
  }
}

.Title {
  font-size: $title-size;
  display: inline-block;

  span {
    display: inline-block;
  }
}

.Text {
  text-align: center;
  font-size: 1rem;

  p {
    margin-top: 0;
    margin-bottom: 1rem;
    a {
      text-decoration: none;
      font-weight: 700;
      color: $white;
    }
  }
}

@include breakpoint('medium') {
  .Home {
    width: 80%;
  }

  .Photo {
    img {
      height: 250px;
      width: 250px;
    }
  }

  .Title {
    font-size: 4rem;
  }

  .Text {
    font-size: 1.2rem;
  }
}

@include breakpoint('large') {
  .Photo {
    img {
      height: 300px;
      width: 300px;
    }
  }

  .Title {
    font-size: 5rem;
  }

  .Text {
    font-size: 1.5rem;
  }
}
