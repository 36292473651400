@import url(https://fonts.googleapis.com/css?family=Sarabun:400,700|Baloo+Thambi+2:400&display=swap);
body{margin:0;font-family:Sarabun, Arial, Helvetica, sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background:#1D1D1D;min-width:21rem}

.Layout_App__3q0ai{color:#FBFBFB;display:grid;grid-template-areas:'header' 'main' 'footer';grid-template-rows:6rem minmax(calc(100vh - 12rem), -webkit-max-content) 6rem;grid-template-rows:6rem minmax(calc(100vh - 12rem), max-content) 6rem}.Layout_Header__2qYVa{grid-area:header}.Layout_Sidebar__2wLNS{display:none;grid-area:sidebar}.Layout_Content__4iIFu{grid-area:main}.Layout_Footer__1h37U{grid-area:footer}@media (min-width: 62rem){.Layout_App__3q0ai{display:grid;grid-template-columns:auto 15rem;grid-template-areas:'header header' 'main sidebar' 'footer none'}.Layout_Sidebar__2wLNS{display:block}}

.Nav_Nav__1kxB_{align-items:center;display:flex;height:100%;justify-content:space-between}.Nav_Title__1b89e{font-family:'Baloo Thambi 2', cursive, sans-serif;margin-left:2rem;width:100%;color:#FBFBFB;font-weight:700;font-size:1.5rem;font-feature-settings:"c2sc";font-variant:small-caps}@media (min-width: 36rem){.Nav_Nav__1kxB_{justify-content:flex-start}}

.Menu_Menu__1DREZ{display:none;background-color:rgba(0,0,0,0.8);color:#FBFBFB;height:100vh;left:0;position:fixed;top:0;z-index:1;width:100vw;-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px)}.Menu_Menu__1DREZ.Menu_Open__3bXdU{display:block}.Menu_Hamburger__-rUxJ{color:#FBFBFB;margin-right:1rem;padding:0.5rem;outline-color:#333}.Menu_CloseTag__2mIVG{margin:0.5rem;padding:0.5rem;position:absolute;outline-color:#333;right:0;top:0}.Menu_Items__CKh_K{display:flex;flex-direction:column;align-items:center;padding:0;margin-top:2.5rem}.Menu_Item__3abZ-{font-size:2.5rem;font-weight:700;list-style:none;margin:1.5rem}.Menu_Item__3abZ- a{color:white;text-decoration:none}@media (min-width: 62rem){.Menu_Menu__1DREZ{display:block;width:13.5rem;height:auto;position:fixed;top:30%;right:0;left:auto;background:#1D1D1D;border-left:2px solid #5B5B5B}.Menu_Menu__1DREZ .Menu_Active__2NRm2{color:#FBFBFB}.Menu_Menu__1DREZ .Menu_Active__2NRm2:before,.Menu_Menu__1DREZ .Menu_Active__2NRm2:After{content:"-";margin:0 0.5rem}.Menu_Hamburger__-rUxJ{display:none}.Menu_CloseTag__2mIVG{display:none}.Menu_Items__CKh_K{height:100%;margin:0;padding:0}.Menu_Item__3abZ-{display:flex;flex-direction:row;align-items:center;justify-content:center;width:100%;height:3rem;font-size:1.5rem;list-style:none;margin:2rem 0}.Menu_Item__3abZ-:hover{background:#5B5B5B}.Menu_Item__3abZ- a{color:#BABABA;padding:0.5rem 1rem}}

.FooterItem_Link__3xlNY{color:#FBFBFB}

.Footer_Footer__37hEc{display:flex;justify-content:flex-end;align-items:center;list-style:none;margin:2rem 2rem}.Footer_Footer__37hEc li{margin:0 1rem}

.Home_Home__EwjGr{display:flex;flex-direction:column;align-items:center;justify-content:flex-start;width:90%;margin:auto}.Home_Photo__7o6Mu{margin-top:3rem}.Home_Photo__7o6Mu img{border-radius:50%;height:200px;width:200px}.Home_Title__3zIM9{font-size:3rem;display:inline-block}.Home_Title__3zIM9 span{display:inline-block}.Home_Text__1SJsk{text-align:center;font-size:1rem}.Home_Text__1SJsk p{margin-top:0;margin-bottom:1rem}.Home_Text__1SJsk p a{text-decoration:none;font-weight:700;color:#FBFBFB}@media (min-width: 62rem){.Home_Home__EwjGr{width:80%}.Home_Photo__7o6Mu img{height:250px;width:250px}.Home_Title__3zIM9{font-size:4rem}.Home_Text__1SJsk{font-size:1.2rem}}@media (min-width: 75rem){.Home_Photo__7o6Mu img{height:300px;width:300px}.Home_Title__3zIM9{font-size:5rem}.Home_Text__1SJsk{font-size:1.5rem}}

.Resume_Resume__3o8iK{display:flex;flex-direction:column;align-items:center;justify-content:flex-start;width:90%;margin:auto;width:90%}.Resume_Title__2k6ub{font-size:3rem}.Resume_Timeline__24Nl4{position:relative;margin:0}.Resume_Timeline__24Nl4:before{content:"";border-left:5px solid #FBFBFB;left:0;position:absolute;bottom:0;top:0}.Resume_Timeline__24Nl4:after{content:"";left:-10px;position:absolute;bottom:0;width:25px;height:25px;border-radius:50%;background:#FBFBFB}.Resume_Timeline__24Nl4 .Resume_Item__cKsRz{padding:0 1rem 3rem 1rem;position:relative;list-style:none}.Resume_Timeline__24Nl4 .Resume_Item__cKsRz:before{content:"";position:absolute;top:0;left:-57px;width:30px;height:30px;border-radius:50%;background:#1D1D1D;border:5px solid #FBFBFB}@media (min-width: 62rem){.Resume_Resume__3o8iK{width:80%}}

.Item_Content__34Kgb{position:relative;background:#333;border-radius:2px;padding:1em;border-radius:10px}.Item_Content__34Kgb::before{content:'';position:absolute;top:13px;right:100%;border:10px solid transparent;border-right:10px solid #333}.Item_Content__34Kgb .Item_Info__1ZL1h{background:#4d4d4d;box-shadow:inset 0 2px 0 rgba(64,64,64,0.5);border-radius:2px;display:flex;justify-content:flex-start;flex-wrap:wrap}.Item_Content__34Kgb .Item_Info__1ZL1h span{display:inline-block;margin:0.5rem 1rem}.Item_Content__34Kgb .Item_Info__1ZL1h i{margin-right:0.5rem}.Item_Content__34Kgb p{text-align:justify}.Item_Content__34Kgb h2{margin-top:0;margin-bottom:1rem}.Item_Content__34Kgb .Item_Skills__2pCyq{font-size:0.8rem;padding:0;margin:0;display:flex;flex-wrap:wrap;justify-content:center}.Item_Content__34Kgb .Item_Skills__2pCyq li{background:#666;border-radius:2px;display:inline-block;padding:2px 10px;color:rgba(255,255,255,0.7);margin:3px 2px;text-align:center;flex-grow:1}

